import React, { FunctionComponent, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SideNavContentStyle } from './SideNavContentStyle';
import Card from '../../../components/Card';
import CartList from '../../../components/Cart/CartList';
import ImageIcon from '../../../components/ImageIcon';
import { sumPriceInSource } from '../../../utils/math';
import { RootState } from '../../../reducers';
import { ImageSource, Coupon, PhotoPrice } from '../../../reducers/gallery/model';
import { checkOpenNav } from '../../../reducers/gallery/action';
import { css } from '@emotion/core';
import Button from '../../../components/Form/Button';
import { navigate } from '@reach/router';
import { saveCoupon, getCoupon, getImageGallery } from '../../../reducers/gallery/action';
import { checkCoupon } from '../../../api/index';

import ConfirmModal from '../../../components/ConfirmModal';
import { FadeLoader } from 'react-spinners';

interface SideNavContentProps {
  refs: { [key: string]: HTMLElement };
  cartList: ImageSource[];
  imgSource: ImageSource[];
  coupon: Coupon;
  getCouponAction: () => void;
  saveCouponAction: (coupon?: Coupon) => void;
  photoPrices: PhotoPrice[];
}

interface ConfirmModal {
  className?: string;
  header?: string;
  body?: string;
  buttonText?: string;
  showing?: boolean;
  callback?: () => void;
}

const SideNavContent: FunctionComponent<SideNavContentProps> = props => {
  const { refs, cartList, coupon, saveCouponAction, imgSource, photoPrices } = props;

  const dispatch = useDispatch();
  const closeNav = () => {
    refs.navRef.classList.remove('active--nav');
    refs.mainRef.style.transition = 'all 0.5s';
    refs.mainRef.style.width = `calc(100%)`;
    refs.mainRef.classList.remove('main--open--nav');
    checkOpenNav(false);
    dispatch(checkOpenNav(false));
  };
  const [couponCode, setCoupon] = useState(coupon ? coupon : null);
  const [state, setState] = useState({
    isLoading: false
  });

  const [confirmation, setConfirmation] = useState<ConfirmModal>({
    header: '',
    body: '',
    buttonText: '',
    showing: false,
    callback: () => setConfirmation({ ...confirmation, showing: false })
  });

  const gotoCheckoutPage = () => {
    closeNav();
    navigate('/checkout');
  }

  const onChangeCoupon = (event: any) => {
    setCoupon({ ...coupon, code: event.target.value.toUpperCase() });
  }

  const applyCoupon = () => {
    if (!couponCode || couponCode!.code.trim() === '') {
      return;
    }
    const data = checkCoupon(couponCode!.code.trim());
    setState({ isLoading: true });

    data.then(
      response => {
        setState({ isLoading: false });

        if (response.data.success) {
          saveCouponAction(response.data.data);
        } else {
          setConfirmation({
            header: 'Discount code',
            body: response.data.message,
            buttonText: 'OK',
            showing: true,
            callback: () => setConfirmation({ ...state, showing: false })
          });
        }
      },
      error => {
        setState({ isLoading: false });
        setConfirmation({
          header: 'Discount code',
          body: error.stack,
          buttonText: 'OK',
          showing: true,
          callback: () => setConfirmation({ ...state, showing: false })
        });
      }
    )
  }

  useEffect(() => {
    // getCouponAction();
    setCoupon(coupon);
  }, [coupon]);

  return (
    <SideNavContentStyle>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 10;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          display: ${confirmation.showing ? 'flex' : 'none'};
          justify-content: center;
          align-items: center;
          .spinner {
            transform: translateY(-4em);
          }
        `}>
        <ConfirmModal
          header={confirmation.header}
          body={confirmation.body}
          buttonText={confirmation.buttonText}
          callback={confirmation.callback}
          css={css`
            .header {
              .title {
                padding: 0 50px;
              }
            }

            @media only screen and (max-width: 320px) {
              .description {
                padding: 0 16px;
              }
            }
          `} />
      </div>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 8;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          display: ${state.isLoading ? 'flex' : 'none'};
          justify-content: center;
          align-items: center;
          .spinner {
            transform: translateY(-4em);
          }
      `}
      >
        <div className="spinner">
          <FadeLoader color="#fff" />
        </div>
      </div>
      <div className="side-nav-header">
        <span className="close-btn" onClick={closeNav}>
          &times;
        </span>
        <img className="side-nav-logo" src="/logo.svg" alt="yellow-logo-nav" />
      </div>
      <div className="side-nav-body">
        <Card
          css={css`
            box-shadow: 0 0 14px rgba(29,35,50,0.05);
            visibility: ${imgSource.length > 0 && imgSource.filter(img => !img.purchased).length > 0 ? 'visible' : 'hidden'}
          `}
          className="expired-label"
          hasHoverEffect={false}>
          <ImageIcon src="/feature-gallery/alert.svg" alt="alert-expired" className="img__icon" />
          <p>
            PHOTOS AVAILABLE UNTIL: <span>{imgSource.filter(img => !img.purchased).length > 0 ? imgSource.filter(img => !img.purchased)[0].expiredDate : ''}</span>
          </p>
        </Card>
        <CartList sources={cartList} className="cart--list" />
        <div className="input-control discount-code">
          <input className="input-discount-code" autoComplete="off" placeholder="Have a discount code?" maxLength={10} onChange={onChangeCoupon} value={couponCode ? couponCode.code : ''} />
          <Button className="btn-apply" label="Apply" onClick={applyCoupon} />
        </div>
      </div>
      <div className="side-nav-footer">
        <div className="purchase-info">
          <span className="photo-total">{cartList.length} photo{cartList.length > 1 ? 's' : ''}</span>
          <span className="total-label">
            Total
            <br />
            <br />
            <span className="photo-total discount"
              css={css`
               visibility: ${cartList.length > 0 && couponCode && couponCode.discountAmount ? 'visible' : 'hidden'};
            `}>£{(parseFloat(sumPriceInSource(cartList, photoPrices)) - (couponCode ? couponCode.discountAmount : 0)).toFixed(2)}</span>
            <span className="photo-total count"
              css={css`
               text-decoration: ${cartList.length > 0 && couponCode && couponCode.discountAmount ? 'line-through' : 'none'};
              `}
            >£{sumPriceInSource(cartList, photoPrices)}</span>
          </span>
        </div>
        <Button
          icon={<ImageIcon src="/icon/right_arrow.svg" className="purchase-icon" />}
          label="Purchase your photos"
          color="#1D2332"
          background="#FBCA00"
          className={`purchase-btn ${cartList.length === 0 ? 'disabled' : ''}`}
          onClick={gotoCheckoutPage}
        />
      </div>
    </SideNavContentStyle>
  );
};

const mapStateToProps = (state: RootState) => ({
  refs: state.galleryReducer.refs,
  cartList: state.galleryReducer.cartList,
  coupon: state.galleryReducer.coupon,
  imgSource: state.galleryReducer.sources,
  photoPrices: state.galleryReducer.photoPrices
});

const mapDispatchToProps = {
  getCouponAction: getCoupon,
  saveCouponAction: saveCoupon,
  getImageGalleryAction: getImageGallery,
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNavContent);
