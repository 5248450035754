import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { css } from '@emotion/core';
import ImageIcon from '../../ImageIcon';
import { ImageSource } from '../../../reducers/gallery/model';
import { RootState, ActionBase } from '../../../reducers';
import { addImageToCart, removeImageFromCart, checkOpenNav } from '../../../reducers/gallery/action';
import { eventEmitter } from '../../../config/eventEmitter';
import { SlickItemStyle } from './SlickItemStyle';
import { navWidth } from '../../../utils/constant';
import download from 'downloadjs';

export interface SlickItemProps {
  cartList: ImageSource[];
  imgInfo: ImageSource;
  imgUrl?: string;
  refs: { [key: string]: HTMLDivElement };
  addImageToCartAction: (imgInfo: ImageSource) => void;
  removeImageFromCartAction: (id: string) => void;
  checkOpenNav?: (isOpenNav: boolean) => ActionBase;
}

const SlickItem: FunctionComponent<SlickItemProps> = props => {
  const { imgInfo, imgUrl, refs, addImageToCartAction, removeImageFromCartAction, cartList } = props;
  const [isAdded, setAdded] = useState(false);
  const [isHover, setHover] = useState(false);
  const [backgroundSize, setBackgroundSize] = useState('cover');
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const dispatch = useDispatch();
  // Here's how we'll keep track of our component's mounted state
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  eventEmitter.on('cart-remove', (id: string) => {
    if (componentIsMounted.current && id === imgInfo.id) {
      setAdded(false);
    }
  });

  eventEmitter.on('add-all-photos', (isAdd: boolean) => {
    if (componentIsMounted.current && isAdd && cartList.findIndex(v => v.id === imgInfo.id) < 0 && !imgInfo.purchased) {
      addImageToCartAction(imgInfo);
      setAdded(true);
    } else if (componentIsMounted.current && !isAdd && cartList.findIndex(v => v.id === imgInfo.id) > -1) {
      removeImageFromCartAction(imgInfo.id!);
      setAdded(false);
    }
  });

  const openNav = () => {
    refs.navRef.classList.add('active--nav');
    refs.mainRef.classList.add('main--open--nav');
    refs.mainRef.style.transition = 'all 0.3s';
    refs.mainRef.style.width = `calc(100% - ${navWidth})`;
    dispatch(checkOpenNav(true));
  };

  const addToCart = () => {
    addImageToCartAction(imgInfo);
  };

  const removeFromCart = () => {
    if (!imgInfo.id) return;
    removeImageFromCartAction(imgInfo.id);
  };

  const onHandleCart = () => {
    if (isAdded) {
      setAdded(false);
      removeFromCart();
    } else {
      openNav();
      setAdded(true);
      addToCart();
    }
  };

  useEffect(() => {
    const indexFound = cartList.findIndex(v => imgInfo.id === v.id);
    if (componentIsMounted.current && indexFound > -1 && !imgInfo.purchased) {
      setAdded(true);
    }

    var img = new Image();
    img.onload = function () {
      if (this.width < this.height) {
        setBackgroundSize('contain');
      }
      let ratio = this.height / 600;
      setImageWidth(this.width / ratio);
      setImageHeight(this.height / ratio);
    }
    img.src = imgUrl;
  }, []);

  if (!imgInfo) return null;

  return (
    <SlickItemStyle isAdded={isAdded} imgUrl={imgUrl} backgroundSize={backgroundSize} imageWidth={imageWidth} imageHeight={imageHeight}>
      <div className="btn-group">
        {
          !imgInfo.purchased ? (
            <button
              type="button"
              className="outline-btn"
              onClick={onHandleCart}
              onMouseEnter={() => {
                setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
            >
              {!isAdded ? (
                <ImageIcon src="/icon/white-cart.svg" css={css`margin: 0 4px 0 0;`} />
              ) : isHover ? (
                <ImageIcon src="/icon/remove.svg" width={0.625} height={0.625} css={css`margin: 0 4px 0 0;`} />
              ) : (
                    <ImageIcon src="/icon/added.svg" width={0.625} height={0.625} css={css`margin: 0 4px 0 0;`} />
                  )}
              {!isAdded ? 'Add to cart' : isHover ? 'Delete from cart' : 'Added to cart'}
            </button>
          ) :
            (
              <button
                type="button"
                className="outline-btn"
                onClick={() => {
                  var x = new XMLHttpRequest();
                  x.open("GET", imgInfo.imgUrl, true);
                  x.responseType = "blob";
                  x.onload = function (e) { download(e.target.response, imgInfo.name, "image/jpeg"); };
                  x.send();
                }}
                onMouseEnter={() => {
                  setHover(true);
                }}
                onMouseLeave={() => {
                  setHover(false);
                }}
              >
                <span className="btn-label">Download photo</span>
              </button>
            )
        }

      </div>
    </SlickItemStyle>
  );
};

const mapStateToProps = (state: RootState) => ({
  refs: state.galleryReducer.refs,
  cartList: state.galleryReducer.cartList,
});

const mapDispatchToProps = {
  addImageToCartAction: addImageToCart,
  removeImageFromCartAction: removeImageFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(SlickItem);
