import React, { FunctionComponent } from 'react';
import Masonry from 'react-masonry-component';
import { useSelector } from 'react-redux';
import GridItem from './GridItem';
import { ImageSource } from '../../reducers/gallery/model';
import { GridImageStyle } from './GridImageStyle';
import { ActionBase, RootState } from '../../reducers';

interface GridImageProps {
  sources: ImageSource[];
  checkOpenNav?: (isOpenNav: boolean) => ActionBase;
}

const GridImage: FunctionComponent<GridImageProps> = props => {
  const { sources } = props;
  const { isOpenNav } = useSelector((rootState: RootState) => rootState.galleryReducer);
  // if (!sources || sources.length <= 0) return null;
  const masonryOptions = {
    transitionDuration: '0.1s',
  };

  return (
    <GridImageStyle isOpenNav={isOpenNav}>
      {
        sources && sources.length > 0 ? (
          <Masonry enableResizableChildren className="masonry--class row" elementType="div" options={masonryOptions}>
            {sources.map((source, idx) => {
              return (
                <div className="column" key={idx}>
                  <GridItem imgInfo={source} className="grid--item" sliderPosition={idx} />
                </div>
              );
            })}
          </Masonry>
        ) :
          (
            <div>
              <span>You have no photos</span>
            </div>
          )
      }
    </GridImageStyle>
  );
};

export default GridImage;
