import React, { FunctionComponent } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';

import SlickItem from './SlickItem';
import { SlickSliderStyle } from './SlickSliderStyle';
import { ImageSource } from '../../reducers/gallery/model';

interface CustomArrowProps {
  className?: string;
  style?: any;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface SlickSliderProps {
  sources: ImageSource[];
  positionGoTo?: number;
}

const CustomNextArrow: FunctionComponent<CustomArrowProps> = props => {
  const { className, style, onClick } = props;

  return (
    <div className={className} style={{ ...style, display: 'block', background: 'transparent' }} onClick={onClick}>
      <IoIosArrowRoundBack />
    </div>
  );
};

const CustomPrevArrow: FunctionComponent<CustomArrowProps> = props => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: 'block', background: 'transparent' }} onClick={onClick}>
      <IoIosArrowRoundForward />
    </div>
  );
};

const SlickSlider: FunctionComponent<SlickSliderProps> = props => {
  const { sources, positionGoTo = 0 } = props;
  if (!sources || sources.length <= 0) return null;

  const settings = {
    className: 'center',
    centerMode: true,
    slidesToShow: sources.length > 3 ? 3 : 1,
    slidesToScroll: 1,
    infinite: true,
    nextArrow: <CustomPrevArrow />,
    prevArrow: <CustomNextArrow />,
    initialSlide: 0,
    variableWidth: true,
    speed: 200,
    fade: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: sources.length > 3 ? 3 : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
        },
      },
    ],
  };

  const slickGoTo = (slider: any) => {
    slider?.slickGoTo(positionGoTo, false);
  };

  return (
    <SlickSliderStyle isOnePhoto={sources.length === 1}>
      <Slider ref={slider => slickGoTo(slider)} {...settings}>
        {sources &&
          sources.length > 0 &&
          sources.map(item => {
            return (
              <SlickSliderStyle key={item.id} isOnePhoto={sources.length === 1}>
                <SlickItem imgInfo={item} imgUrl={item.imgUrl} />
              </SlickSliderStyle>
            );
          })}
      </Slider>
    </SlickSliderStyle>
  );
};

export default SlickSlider;
